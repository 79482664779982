import { template as template_cf0d0c2604604aac8b0c4a50a69258c0 } from "@ember/template-compiler";
const WelcomeHeader = template_cf0d0c2604604aac8b0c4a50a69258c0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
